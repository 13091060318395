import AwesomeDebouncePromise from "awesome-debounce-promise";
import { addDays, format, setHours, setMinutes, startOfWeek } from "date-fns";
import { flatten, groupBy, values } from "lodash";
import { apiCall } from "./apiCalls";

//  █████╗ ██╗   ██╗████████╗ ██████╗  ██████╗ ██████╗ ███╗   ███╗██████╗ ██╗     ███████╗████████╗███████╗
// ██╔══██╗██║   ██║╚══██╔══╝██╔═══██╗██╔════╝██╔═══██╗████╗ ████║██╔══██╗██║     ██╔════╝╚══██╔══╝██╔════╝
// ███████║██║   ██║   ██║   ██║   ██║██║     ██║   ██║██╔████╔██║██████╔╝██║     █████╗     ██║   █████╗
// ██╔══██║██║   ██║   ██║   ██║   ██║██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║     ██╔══╝     ██║   ██╔══╝
// ██║  ██║╚██████╔╝   ██║   ╚██████╔╝╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ███████╗███████╗   ██║   ███████╗
// ╚═╝  ╚═╝ ╚═════╝    ╚═╝    ╚═════╝  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝     ╚══════╝╚══════╝   ╚═╝   ╚══════╝
//
const autoCompleteFunctions = {};

export const negenTweeAutocomplete = (language, concession) => {
	const key = `${language}/${concession}`;
	if (!autoCompleteFunctions[key]) {
		autoCompleteFunctions[key] = AwesomeDebouncePromise(
			async (query) => {
				// Get locations
				try {
					return await apiCall(`/directions/9292/locations`, {
						query,
						language,
						concession,
					});
				} catch (error) {
					console.error(error);
					return [];
				}
			},
			250,
			{},
		);
	}

	return autoCompleteFunctions[key];
};

// ██████╗ ██████╗  ██████╗ ██████╗ ██╗   ██╗ ██████╗████████╗     █████╗ ██████╗ ██╗   ██╗██╗ ██████╗███████╗
// ██╔══██╗██╔══██╗██╔═══██╗██╔══██╗██║   ██║██╔════╝╚══██╔══╝    ██╔══██╗██╔══██╗██║   ██║██║██╔════╝██╔════╝
// ██████╔╝██████╔╝██║   ██║██║  ██║██║   ██║██║        ██║       ███████║██║  ██║██║   ██║██║██║     █████╗
// ██╔═══╝ ██╔══██╗██║   ██║██║  ██║██║   ██║██║        ██║       ██╔══██║██║  ██║╚██╗ ██╔╝██║██║     ██╔══╝
// ██║     ██║  ██║╚██████╔╝██████╔╝╚██████╔╝╚██████╗   ██║       ██║  ██║██████╔╝ ╚████╔╝ ██║╚██████╗███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═════╝  ╚═════╝  ╚═════╝   ╚═╝       ╚═╝  ╚═╝╚═════╝   ╚═══╝  ╚═╝ ╚═════╝╚══════╝
//

const productAdviceFunctions = {};
export const getProductAdviceOptions = (language, concession) => {
	const key = `${language}/${concession}`;
	if (!productAdviceFunctions[key]) {
		productAdviceFunctions[key] = AwesomeDebouncePromise(
			async (from, to, modalities) => {
				// Get locations
				try {
					// Create basic options
					const options = {
						from_location: from.id,
						to_location: to.id,
						vehicle_types: modalities.join(","),
						time_type: "Departure",
					};

					// Now create the moments
					const sundayAfternoon = setHours(
						startOfWeek(addDays(new Date(), 7), 0),
						14,
					);
					const wednesdayMorning = setMinutes(
						setHours(addDays(sundayAfternoon, 3), 8),
						30,
					);
					const fridayEvening = setHours(addDays(wednesdayMorning, 2), 17);

					// And create the payloads, and do the calls
					const payloads = [
						sundayAfternoon,
						wednesdayMorning,
						fridayEvening,
					].map((date) => ({
						...options,
						date: format(date, "yyyy-MM-dd"),
						time: format(date, "HH:mm"),
					}));
					const promises = payloads.map((payload) =>
						apiCall("/directions/9292/routes", payload, true, {
							method: "post",
						}),
					);
					const results = await Promise.all(promises);

					// Combine the journeys from all the results
					const journeys = flatten(
						results.map((result, resultIndex) => {
							// Get the journey
							const { journeys } = result[0];

							// Get the matching timestamp too
							return journeys.map((journey, journeyIndex) => ({
								...journey,
								index: journeyIndex,
								payload: payloads[resultIndex],
							}));
						}),
					);

					return { error: null, journeys: groupJourneysByRoute(journeys) };
				} catch (error) {
					return { error, journeys: [] };
				}
			},
			250,
			{},
		);
	}

	return productAdviceFunctions[key];
};

export const groupJourneysByRoute = (journeys) => {
	// Group it
	const grouped = groupBy(journeys, (journey) => {
		// Get non-walk legs
		const legs = journey.legs.filter((leg) => leg.modality_group !== "Walking");

		// Return identifier for that leg
		return legs
			.map(
				(leg) =>
					`${leg.operator_name}|${leg.modality_group === "Train" ? "" : leg.service}`,
			)
			.join("//");
	});

	// Now just take the first one from each
	return values(grouped).map((group) => group[0]);
};
