import "./publicPath";
import React from "react";
import ReactDOM from "react-dom";
import { WidgetProvider } from "./components/hooks/useWidget";
import NotFound from "./components/widgets/NotFound";
import ProductadviesWidget from "./components/widgets/ProductadviesWidget";

function getWidget(key) {
	switch (key) {
		case "productadvies":
			return ProductadviesWidget;

		default:
			return NotFound;
	}
}

export function init({ containerId, container, props = {} } = {}) {
	let loadContainer = container || document.getElementById(containerId);

	// Get widget class
	const { widget, ...other } = props;
	const Widget = getWidget(props.widget);

	ReactDOM.render(
		<WidgetProvider value={other}>
			<Widget {...other} />
		</WidgetProvider>,
		loadContainer,
	);
}
export function clean({ container } = {}) {
	if (container) {
		ReactDOM.unmountComponentAtNode(container);
	}
}

if (process.env.NODE_ENV === "development") {
	["productadvies"].forEach((key) => {
		init({
			containerId: key,
			props: {
				widget: key,
				language: "nl",
				concession: "blauwnet",
				device: "desktop",
			},
		});
	});
}
