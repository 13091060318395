import React, { useMemo } from 'react'
import { WidgetContext } from './useWidget'

// ███╗   ██╗██╗
// ████╗  ██║██║
// ██╔██╗ ██║██║
// ██║╚██╗██║██║
// ██║ ╚████║███████╗
// ╚═╝  ╚═══╝╚══════╝
//
const NL = {
	productadvies: {
		title: 'Voer je reis in en kies je route',
		intro: 'Voor welke reis wil je een los kaartje of abonnementsadvies?',
		from: 'Van',
		to: 'Naar',
		selectRoute: 'Selecteer de route die je (meestal) gebruikt en klik op ‘Kies deze route’.',
		button: 'Kies deze route',
		assets: {
			Train: ({ leg }) => `${leg.operator_name} richting ${leg.destination}`,
			Bus: ({ leg }) => `${leg.modality_description} ${leg.service}`,
		},
		noTrips: 'Er zijn voor de gekozen reis geen routes gevonden.',
		modalities: {
			bus: 'Alleen bus',
			busAndTrain: 'Bus en/of trein',
		},
		incompleteFareInfo: 'Er is helaas geen complete prijsinformatie beschikbaar voor de geselecteerde route. Daardoor is het niet mogelijk om een geldig productadvies te geven.'
	},
	negenTweeAutocomplete: {
		placeholder: 'Adres, postcode of station',
		loading: 'Zoeken...',
		noResults: {
			forQuery: ({ query }) => `Geen locaties gevonden voor ${query}`,
			empty: 'Vul een adres of station in om een locatie te zoeken',
		},
		type: {
			station: 'Station',
			poi: 'POI',
			halte: 'Halte',
			adres: 'Adres',
		},
	},
}

// ███████╗███╗   ██╗
// ██╔════╝████╗  ██║
// █████╗  ██╔██╗ ██║
// ██╔══╝  ██║╚██╗██║
// ███████╗██║ ╚████║
// ╚══════╝╚═╝  ╚═══╝
//
const EN = {
	productadvies: {
		title: 'Enter your trip and choose your route',
		intro: 'For which trip do you need a (season) ticket?',
		from: 'From',
		to: 'To',
		selectRoute: "Select the route you (usually) take and click 'Choose this route'.",
		button: 'Choose this route',
		assets: {
			Train: ({ leg }) => `${leg.operator_name} to ${leg.destination}`,
			Bus: ({ leg }) => `${leg.modality_descsription} ${leg.service}`,
		},
		noTrips: 'No routes could be found for your trip.',
		modalities: {
			bus: 'Bus only',
			busAndTrain: 'Bus and/or train',
		},
	},

	negenTweeAutocomplete: {
		placeholder: 'address, postal code, or station',
		loading: 'Searching...',
		noResults: {
			forQuery: ({ query }) => `No locations found for ${query}`,
			empty: 'Enter an address or station to find a location',
		},
		type: {
			station: 'Station',
			poi: 'POI',
			halte: 'Stop',
			adres: 'Address',
		},
	},
}

// ██╗  ██╗ ██████╗  ██████╗ ██╗  ██╗
// ██║  ██║██╔═══██╗██╔═══██╗██║ ██╔╝
// ███████║██║   ██║██║   ██║█████╔╝
// ██╔══██║██║   ██║██║   ██║██╔═██╗
// ██║  ██║╚██████╔╝╚██████╔╝██║  ██╗
// ╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚═╝  ╚═╝
//

export const useDictionary = (prefix = null, language = null) => {
	// Check language
	const ctx = React.useContext(WidgetContext)
	const lang = language || (ctx ? ctx.language : 'nl')

	// Create result
	const result = useMemo(() => {
		const Data = lang === 'en' ? EN : NL
		const t = (key, attributes = {}) => {
			let k = prefix ? `${prefix}.${key}` : key
			const parts = k.split(/\./)
			let o = Data
			while (parts.length > 0) {
				const p = parts.shift()
				if (!o[p]) return `[${k}]`
				o = o[p]
			}
			if (typeof o === 'function') return o(attributes)
			return o
		}

		return {
			t,
		}
	}, [lang, prefix])

	return result
}
