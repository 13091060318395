import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { getProductAdviceOptions } from '../../api/negenTwee'
import { Config } from '../../config'
import { Theme } from '../../theme'
import NegenTweeAutocomplete from '../forms/NegenTweeAutocomplete'
import Radio from '../forms/Radio'
import ErrorMessage from '../gfx/ErrorMessage'
import { useDictionary } from '../hooks/useDictionary'
import { useWidget } from '../hooks/useWidget'
import SelectNegenTweeRoute from '../product-advice/SelectNegenTweeRoute'

// ███████╗████████╗██╗   ██╗██╗     ███████╗██████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔══██╗
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ██║  ██║
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ██║  ██║
// ███████║   ██║      ██║   ███████╗███████╗██████╔╝
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚═════╝
//

const Container = styled.div`
	text-align: left;
	border-radius: 4px;
`
const Locations = styled.div`
	display: flex;
	> * {
		margin-bottom: 12px;
	}

	@media screen and (min-width: ${Theme.breakpoints.mobile}) {
		> * {
			width: 50%;
			margin: 0 12px;
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
	@media screen and (max-width: ${Theme.breakpoints.mobile}) {
		flex-direction: column;
	}
`

const Form = styled.div`
	padding: 20px;
	margin-bottom: 20px;
	${props =>
		props.disabled &&
		css`
			pointer-events: none;
			opacity: 0.7;
		`}
`

//  ██████╗ ██████╗ ███╗   ███╗██████╗  ██████╗ ███╗   ██╗███████╗███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗ ████║██╔══██╗██╔═══██╗████╗  ██║██╔════╝████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔████╔██║██████╔╝██║   ██║██╔██╗ ██║█████╗  ██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╔╝██║██╔═══╝ ██║   ██║██║╚██╗██║██╔══╝  ██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚═╝ ██║██║     ╚██████╔╝██║ ╚████║███████╗██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝      ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═══╝   ╚═╝
//

const DEBUG = false
const FROM_DEFAULT_VALUE = DEBUG
	? JSON.parse(
			'{"id":"station-zwolle","modelType":"directions.location","type_name":"Treinstation","display_name":"Zwolle","station_abbreviation":"zl","type":"Station","type_description":"Treinstation","disabled_accessible":"Accessible","visually_accessible":"Accessible","latitude":52.505184,"longitude":6.091077,"place_id":"zwolle","place_name":"Zwolle","region_code":"OV","region_name":"Overijssel","show_region":false,"country_code":"NL","country_name":"Nederland","show_country":false,"district_name":"Zwolle","place_latitude":52.505185,"place_longitude":6.091078,"coordinate":{"id":"1","modelType":"directions.coordinate","latitude":52.505184,"longitude":6.091077},"place":{"id":"1","modelType":"directions.place","country_code":"NL","country_name":"Nederland","name":"Zwolle","region_name":"Overijssel","region_code":"OV","show_region":false,"show_country":false}}'
	  )
	: undefined
const TO_DEFAULT_VALUE = DEBUG
	? JSON.parse(
			'{"id":"station-enschede","modelType":"directions.location","type_name":"Treinstation","display_name":"Enschede","station_abbreviation":"es","type":"Station","type_description":"Treinstation","disabled_accessible":"Accessible","visually_accessible":"Accessible","latitude":52.222362,"longitude":6.890257,"place_id":"enschede","place_name":"Enschede","region_code":"OV","region_name":"Overijssel","show_region":false,"country_code":"NL","country_name":"Nederland","show_country":false,"district_name":"Enschede","place_latitude":52.222382,"place_longitude":6.890199,"coordinate":{"id":"1","modelType":"directions.coordinate","latitude":52.222362,"longitude":6.890257},"place":{"id":"1","modelType":"directions.place","country_code":"NL","country_name":"Nederland","name":"Enschede","region_name":"Overijssel","region_code":"OV","show_region":false,"show_country":false}}'
	  )
	: undefined

const ProductadviesWidget = () => {
	///////////////
	// Resources //
	///////////////

	const { t } = useDictionary('productadvies')
	const { language, concession } = useWidget()

	///////////
	// State //
	///////////

	const [isLoading, setIsLoading] = useState(false)
	const [from, setFrom] = React.useState(FROM_DEFAULT_VALUE)
	const [to, setTo] = React.useState(TO_DEFAULT_VALUE)
	const [modalities, setModalities] = React.useState(concession === 'blauwnet' ? 'Bus,Train' : 'Bus')

	const [journeys, setTrips] = React.useState([null, null, null])
	const [selectedIndex, setSelectedIndex] = React.useState(-1)
	const [error, setError] = React.useState(null)

	/////////////
	// Effects //
	/////////////

	const loadOptions = React.useCallback(async () => {
		// Reset
		setTrips([null, null, null])
		setSelectedIndex(-1)
		setError(null)
		if (!from || !to) return
		setIsLoading(true)

		try {
			// Get the data
			const { error, journeys } = await getProductAdviceOptions(language, concession)(
				from,
				to,
				modalities.split(/,/)
			)

			setError(error)
			setTrips(journeys)
			setSelectedIndex(0)
		} catch (error) {
			console.error(error)
			setError(error)
		} finally {
			setIsLoading(false)
		}
	}, [from, to, modalities, isLoading, language])
	React.useEffect(() => {
		loadOptions()
	}, [from, to, modalities, language])

	////////////
	// Submit //
	////////////

	const selectedJourney = useMemo(() => {
		if (!journeys || selectedIndex === -1) return null
		return journeys[selectedIndex]
	}, [journeys, selectedIndex])

	const submitUrl = useMemo(() => {
		// Get the journey
		const journey = selectedJourney
		if (!journey) return null
		const { payload } = journey

		// Format the date
		const { date } = payload
		const time = payload.time.replace(/:/, 'u')
		const from = payload.from_location.replace(/\//, '--')
		const to = payload.to_location.replace(/\//, '--')

		// Check the vehicles
		const vehicles = payload.vehicle_types
			.split(/,/)
			.map(v => v.toLowerCase())
			.join('-')

		return `${
			Config.siteUrl
		}/${language}/${concession}/reisplanner/plannen/van/${from}/naar/${to}/vertrek/${date}/${time}/${vehicles}/${
			journey.index
		}/product-advies`
	}, [selectedJourney, language, concession])


	return (
		<Container>
			<h2>{t('title')}</h2>
			<p>{t('intro')}</p>

			<div className='bg-light-default'>
				<Form disabled={isLoading}>
					<Locations>
						<NegenTweeAutocomplete value={from} onChange={v => setFrom(v)} label={t('from')} />
						<NegenTweeAutocomplete value={to} onChange={v => setTo(v)} label={t('to')} />
					</Locations>
					<Radio
						value={modalities}
						onChange={v => setModalities(v)}
						options={[
							{ label: t('modalities.busAndTrain'), value: 'Bus,Train' },
							{ label: t('modalities.bus'), value: 'Bus' },
						]}
					/>
				</Form>
			</div>

			{from && to && (
				<>
					<p>{t('selectRoute')}</p>
					<SelectNegenTweeRoute
						journeys={journeys}
						selectedIndex={selectedIndex}
						onChange={index => !isLoading && setSelectedIndex(index)}
					/>

					{/* Errors */}
					{journeys.length === 0 && <ErrorMessage error={t('noTrips')} />}
					{error && <ErrorMessage error={error} />}

					{/* Incomplete fare info */}
					{selectedJourney && !selectedJourney.fareInfo.complete && <><ErrorMessage error={t('incompleteFareInfo')}/><br /></>}

					{/* Submit */}
					{submitUrl && (
						<a className={'c-btn c-btn-arrow bg-full-primary c-reisplanner__form--button'} href={submitUrl}>
							<span>{t('button')}</span>
							<i>
								<FontAwesomeIcon icon={faArrowRight} />
							</i>
						</a>
					)}
				</>
			)}
		</Container>
	)
}
export default ProductadviesWidget
